
import axios from "axios";

import {
    Toast
} from "vant";

function summitForm(form) {
    const _this = this
    axios
        .post("https://show.333f.com/holiday-activity-api/public/api/attract/create", form)
        .then(() => {
            Toast("提交成功");
            setTimeout(function () {
                console.log(_this);
                _this.$emit("close");
            }, 1000);
        }).catch(() => {
            Toast("提交失败");
        })
        .finally(() => {
        });
}

export const crowdForm = {
    name: "crowdForm",
    componentName: "crowdForm",
    data() {
        return {
            form: {
                contact_user: "",
                contact_info: "",
                brand: "",
                category: "",
                other: "",
                area: "",
                type: 1
            }
        };
    },
    render(h, scope) {
        return <div class="vc" style={{ height: "100%", display: "flex", justifyContent: "space-evenly", }}>
            <img src={require("@/assets/img/take-out/crowd-form-left-bg.png")} alt="" srcset="" style={{ height: "100%" }} />
            <div class="businessForm vch flex-column" style={{ height: "100%", width: "50%" }}>
                <img src={require("@/assets/img/take-out/crowd-form-right-top.png")} style={{ width: "100%" }} />
                <van-field class="vc" vModel={this.form.contact_user} label="联系人" placeholder="" />
                <van-field class="vc" vModel={this.form.contact_info} label="联系方式" placeholder="" />
                <van-field class="vc" vModel={this.form.area} label="众筹面积" placeholder="" />
                <van-button class="summit-btn" onClick={() => summitForm.call(this, this.form)}>合作提交</van-button>
            </div>
        </div>
    },
}
export const chainsForm = {
    name: "chainsForm",
    componentName: "chainsForm",
    data() {
        return {
            form: {
                contact_user: "",
                contact_info: "",
                brand: "",
                category: "",
                other: "",
                type: 2
            }
        };
    },
    render(h, scope) {
        return <div class="vc" style={{ height: "100%", display: "flex", justifyContent: "space-between" }}>
            <img src={require("@/assets/img/take-out/business-form-left-bg.png")} alt="" srcset="" style={{ height: "100%" }} />
            <div class="businessForm vch flex-column" style={{ height: "100%", width: "50%" }}>
                <van-field class="vc" vModel={this.form.contact_user} label="联系人" placeholder="" />
                <van-field class="vc" vModel={this.form.contact_info} label="联系方式" placeholder="" />
                <van-field class="vc" vModel={this.form.brand} label="入驻品牌" placeholder="" />
                <van-field class="vc" vModel={this.form.category} label="入驻品类" placeholder="" />
                <van-field class="vc" vModel={this.form.other} label="其他需求" placeholder="" />
                <van-button class="summit-btn" onClick={() => summitForm.call(this, this.form)}>合作提交</van-button>
            </div>
        </div>

    },
}
export const brandForm = {
    name: "brandForm",
    componentName: "brandForm",
    data() {
        return {
            form: {
                contact_user: "",
                contact_info: "",
                brand: "",
                category: "",
                other: "",
                area: "",
                type: 3
            }
        };
    },
    render(h, scope) {
        return <div class="vc" style={{ height: "100%", display: "flex", justifyContent: "space-between" }}>
            <img src={require("@/assets/img/take-out/brand-form-left-bg.png")} alt="" srcset="" style={{ height: "97%" }} />
            <div class="businessForm vch flex-column" style={{ height: "100%", width: "50%" }}>
                <van-field class="vc" vModel={this.form.contact_user} label="联系人" placeholder="" />
                <van-field class="vc" vModel={this.form.contact_info} label="联系方式" placeholder="" />
                <van-field class="vc" vModel={this.form.brand} label="入驻品牌" placeholder="" />
                <van-field class="vc" vModel={this.form.category} label="入驻品类" placeholder="" />
                <van-field class="vc" vModel={this.form.area} label="面积要求" placeholder="" />
                <van-field class="vc" vModel={this.form.other} label="其他需求" placeholder="" />
                <van-button class="summit-btn" onClick={() => summitForm.call(this, this.form)}>合作提交</van-button>
            </div>
        </div >

    },
}