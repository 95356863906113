


export const food = {
    name: "food",
    componentName: "food",
    render(h, scope) {
        return <img
            style={{ width: "100%", }}
            class="dialog-img" src={require("@/assets/img/take-out/food-bg.png")} />
    },
}

export const foodLesson = {
    name: "foodLesson",
    componentName: "foodLesson",
    render(h, scope) {
        return <img
            style={{ width: "100%", }}
            class="dialog-img" src={require("@/assets/img/take-out/food-lesson.png")} />
    },
}

export const me = {
    name: "me",
    componentName: "me",
    render(h, scope) {
        return <img
            style={{ width: "100%", }}
            class="dialog-img" src={require("@/assets/img/take-out/me-bg.png")} />
    },
}
export const booking = {
    name: "booking",
    componentName: "booking",
    render(h, scope) {
        return <img
            style={{ width: "100%", }}
            class="dialog-img" src={require("@/assets/img/take-out/booking-bg.png")} />
    },
}
export const active = {
    name: "active",
    componentName: "active",
    render(h, scope) {
        return <img
            style={{ width: "100%", }}
            class="dialog-img" src={require("@/assets/img/take-out/active-bg.png")} />
    },
}