<template>
  <div class="bg vch" v-if="show">
    <div class="container animation-big flex-column">
      <slot name="head"></slot>
      <div @click="close" class="close-btn">
        <slot name="close">
          <div>
            <button>close</button>
          </div>
        </slot>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mDialog",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["config"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(20, 19, 18, 0.4);
  .close-btn {
    position: absolute;
    top: 4.8%;
    right: 2.8%;
    cursor: pointer;
  }
  .container {
    background-repeat: no-repeat;
    background-size: 99% 99%;
    padding: 12px;
    box-sizing: border-box;
    background-position: center;
    border-radius: 22px;
    width: 1132px;
    height: 860px;
    position: relative;
    .content {
      flex: 1;
      width: 100%;
      padding: 80px 140px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }
}
@media (orientation: portrait) {
  .bg {
    transform: rotate(90deg);
    .container {
      transform: scale(2);
    }
  }
}
</style>
