var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-list"},[_c('m-dialog',{staticClass:"take-out-dialog",class:{ isFormDialog: _vm.isForm },attrs:{"show":_vm.show},on:{"close":function($event){_vm.show = false}},scopedSlots:_vm._u([(_vm.isForm)?{key:"head",fn:function(){return [_c('p',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.dialogTitle))])]},proxy:true}:null,{key:"close",fn:function(){return [_c('van-button',{staticClass:"van-button--img back-btn"},[_c('img',{attrs:{"src":require("@/assets/img/take-out/back-btn.png")}})])]},proxy:true}],null,true)},[_c(_vm.currentComponent,{tag:"component",on:{"close":function($event){_vm.show = false}}})],1),_c('div',{staticClass:"menu-list flex-column"},[_c('div',{staticClass:"menu-item-container"},[_c('menu-item',{attrs:{"icon":require('@/assets/img/take-out/business.png')},on:{"click":function($event){_vm.showList1 = !_vm.showList1}}}),_c('p',{staticClass:"menu-item-title"},[_vm._v("招商服务")]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showList1)?_c('div',{staticClass:"menu-item-list vc"},_vm._l(([
              { title: '众筹招商', com: 'crowdForm' },
              { title: '产业链招商', com: 'chainsForm' },
              { title: '品牌招商', com: 'brandForm' },
            ]),function(item,index){return _c('div',{key:item.title,staticClass:"flex-column vch"},[_c('van-button',{staticClass:"van-button--img",on:{"click":function($event){return _vm.handleClickMenu(item, true)}}},[_c('img',{attrs:{"src":require(`@/assets/img/take-out/item-1-${index + 1}.png`)}})]),_c('p',[_vm._v(_vm._s(item.title))])],1)}),0):_vm._e()])],1),_c('div',{staticClass:"menu-item-container"},[_c('menu-item',{attrs:{"icon":require('@/assets/img/take-out/food.png')},on:{"click":function($event){_vm.showList2 = !_vm.showList2}}}),_c('p',{staticClass:"menu-item-title"},[_vm._v("美食活动")]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showList2)?_c('div',{staticClass:"menu-item-list vc"},_vm._l(([
              { title: '活动预约', com: 'active' },
              { title: '美食课堂', com: 'foodLesson' },
              { title: '美食圈子', com: 'food' },
            ]),function(item,index){return _c('div',{key:item.title,staticClass:"flex-column vch"},[_c('van-button',{staticClass:"van-button--img",on:{"click":function($event){return _vm.handleClickMenu(item, false)}}},[_c('img',{attrs:{"src":require(`@/assets/img/take-out/item-2-${index + 1}.png`)}})]),_c('p',[_vm._v(_vm._s(item.title))])],1)}),0):_vm._e()])],1),_c('div',{staticClass:"menu-item-container"},[_c('menu-item',{attrs:{"icon":require('@/assets/img/take-out/booking.png')},on:{"click":function($event){return _vm.handleClickMenu({ com: 'booking' }, false)}}}),_c('p',{staticClass:"menu-item-title"},[_vm._v("订餐服务")])],1),_c('div',{staticClass:"menu-item-container"},[_c('menu-item',{attrs:{"icon":require('@/assets/img/take-out/me.png')},on:{"click":function($event){return _vm.handleClickMenu({ com: 'me' }, false)}}}),_c('p',{staticClass:"menu-item-title"},[_vm._v("个人中心")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }