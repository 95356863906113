<template>
  <div class="button-list">
    <m-dialog
      :show="show"
      @close="show = false"
      class="take-out-dialog"
      :class="{ isFormDialog: isForm }"
    >
      <template #head v-if="isForm">
        <p class="dialog-title">{{ dialogTitle }}</p>
      </template>
      <template #close>
        <van-button class="van-button--img back-btn"
          ><img src="@/assets/img/take-out/back-btn.png"
        /></van-button>
      </template>
      <component :is="currentComponent" @close="show = false"></component>
    </m-dialog>

    <div class="menu-list flex-column">
      <div class="menu-item-container">
        <menu-item
          @click="showList1 = !showList1"
          :icon="require('@/assets/img/take-out/business.png')"
        ></menu-item>
        <p class="menu-item-title">招商服务</p>
        <transition name="slide-fade">
          <div class="menu-item-list vc" v-if="showList1">
            <div
              v-for="(item, index) in [
                { title: '众筹招商', com: 'crowdForm' },
                { title: '产业链招商', com: 'chainsForm' },
                { title: '品牌招商', com: 'brandForm' },
              ]"
              :key="item.title"
              class="flex-column vch"
            >
              <van-button
                class="van-button--img"
                @click="handleClickMenu(item, true)"
                ><img
                  :src="
                    require(`@/assets/img/take-out/item-1-${index + 1}.png`)
                  "
              /></van-button>
              <p>{{ item.title }}</p>
            </div>
          </div>
        </transition>
      </div>

      <div class="menu-item-container">
        <menu-item
          @click="showList2 = !showList2"
          :icon="require('@/assets/img/take-out/food.png')"
        ></menu-item>
        <p class="menu-item-title">美食活动</p>
        <transition name="slide-fade">
          <div class="menu-item-list vc" v-if="showList2">
            <div
              v-for="(item, index) in [
                { title: '活动预约', com: 'active' },
                { title: '美食课堂', com: 'foodLesson' },
                { title: '美食圈子', com: 'food' },
              ]"
              :key="item.title"
              class="flex-column vch"
            >
              <van-button
                class="van-button--img"
                @click="handleClickMenu(item, false)"
                ><img
                  :src="
                    require(`@/assets/img/take-out/item-2-${index + 1}.png`)
                  "
              /></van-button>
              <p>{{ item.title }}</p>
            </div>
          </div>
        </transition>
      </div>

      <div class="menu-item-container">
        <menu-item
          :icon="require('@/assets/img/take-out/booking.png')"
          @click="handleClickMenu({ com: 'booking' }, false)"
        ></menu-item>
        <p class="menu-item-title">订餐服务</p>
      </div>

      <div class="menu-item-container">
        <menu-item
          :icon="require('@/assets/img/take-out/me.png')"
          @click="handleClickMenu({ com: 'me' }, false)"
        ></menu-item>
        <p class="menu-item-title">个人中心</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import mDialog from "@/components/Dialog.vue";
import { food, foodLesson, me, booking, active } from "./imgDialog";
import { crowdForm, chainsForm, brandForm } from "./formDialog";
import "./css.scss";

const menuItem = Vue.extend({
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  render(h) {
    return h(
      "van-button",
      {
        class: "menu-item van-button--img",
        on: {
          click: () => {
            this.$emit("click");
          },
        },
      },
      [
        h("img", {
          class: "center menu-item-img",
          style: { zIndex: 1 },
          attrs: { src: this.icon },
        }),
        h("img", {
          class: "menu-item-bg center",
          attrs: { src: require("@/assets/img/take-out/menu-item-bg.png") },
        }),
      ]
    );
  },
});

export default {
  components: {
    mDialog,
    menuItem,
    food,
    foodLesson,
    crowdForm,
    chainsForm,
    brandForm,
    me,
    booking,
    active,
  },
  data() {
    return {
      show: false,
      showList1: false,
      showList2: false,
      currentComponent: foodLesson,
      dialogTitle: "众筹招商",
      isForm: false,
    };
  },
  mounted() {
    var documentElement = document.documentElement;
    function callback() {
      var clientWidth = documentElement.clientWidth;
      var clientHeight = documentElement.clientHeight;
      if (clientWidth / clientHeight > 16 / 9) {
        console.log(16 / 9 / (clientWidth / clientHeight));
        documentElement.style.fontSize =
          (clientWidth / 100) * (16 / 9 / (clientWidth / clientHeight)) + "px";
      } else {
        documentElement.style.fontSize = clientWidth / 100 + "px";
      }
    }

    document.addEventListener("DOMContentLoaded", callback);
    window.addEventListener(
      "orientationchange" in window ? "orientationchange" : "resize",
      callback
    );
    callback();
  },
  methods: {
    handleShow() {
      this.showList = !this.showList;
    },
    handleClickMenu(item, isForm) {
      this.hideMenuList();
      this.isForm = isForm;
      if (isForm) {
        this.dialogTitle = item.title;
      }
      if (this.$options.components && this.$options.components[item.com]) {
        this.currentComponent = this.$options.components[item.com];
        this.show = !this.show;
      }
    },
    hideMenuList() {
      this.showList1 = false;
      this.showList2 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.take-out-dialog {
  ::v-deep .container {
    width: calc(2958px * 1.2);
    height: calc(1700px * 1.2);
    max-height: 95vh;
    .content {
      padding: 0;
    }
  }
}

.take-out-dialog.isFormDialog {
  ::v-deep .container {
    padding: 0 100px;
    background-color: rgba(27, 26, 23, 1);
    border: 10px solid rgba(48, 44, 45, 1);
  }
  .dialog-title {
    height: 238px;
    width: 100%;
    font-size: 70px;
    line-height: 238px;
    font-weight: 500;
    text-align: center;
    color: #ffeeb1;
    box-sizing: border-box;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 96%;
      height: 5px;
      background-color: rgba(48, 44, 45, 1);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.button-list {
  position: fixed;
  ::v-deep .back-btn {
    width: 216px;
  }
  .menu-list {
    position: fixed;
    top: 14%;
    right: 1.77%;
    * {
      font-family: SansSC-Normal;
    }
  }
  .menu-item-container {
    height: 280px;
    width: 280px;
    position: relative;
    margin-bottom: 108px;
    .menu-item-title {
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #f6ebe6;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 120%);
      word-break: keep-all;
    }
  }
  .menu-item {
    width: 280px;
    height: 280px;
    position: absolute;
    top: 0;
    z-index: 1;
    ::v-deep .menu-item-bg {
      width: 560px;
      pointer-events: none;
      z-index: 0;
    }
    ::v-deep .menu-item-img {
      height: 288px;
      width: auto;
    }
  }
  .menu-item-list {
    width: fit-content;
    height: fit-content;
    padding: 5px 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    border-radius: 35px;
    position: absolute;
    top: 0;
    right: 50%;
    padding-right: calc(280px / 2 + 50px);
    z-index: 0;
    padding-left: 40px;
    column-gap: 62px;
    box-sizing: border-box;
    .van-button--img {
      height: 208px;
      ::v-deep {
        img {
          height: 208px;
          width: auto;
        }
      }
    }
    p {
      font-size: 40px;
      font-weight: 500;
      color: #f6ebe6;
      word-break: keep-all;
    }
  }
}
</style>
